<template>
    <div class="child-not-last:mb-11 desktop-lg:child-not-last:mb-16">
      <QuestionAnswer
        v-for="(q, index) in questions"
        :key="index"
        :question="q.question"
        :answer="q.answer"
        :details="q.details"
      />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import faqsData from '@/data/faqs';
import QuestionAnswer from './FaqsQuestion.vue';

const questions = ref(faqsData);
</script>
