<template>
  <div
    class="
      flex
      flex-col
      tablet:flex-row
      tablet:divide-x

      pt-[20px]
      tablet:pt-[35px]

      divide-gray-500

      [&_section]:mb-[50px]
      tablet:[&_section]:mb-[70px]

      [&_p]:mb-[36px]
      tablet:[&_p]:mb-[50px]

      [&_h2]:mb-[12px]
      tablet:[&_h2]:mb-[18px]
  ">
    <div class="flex-1 tablet:pr-[35px]">
      <section>
        <h2>ATTIRE GUIDELINES</h2>
        <h3>Welcome Reception</h3>
        <p>
          <strong>Business casual attire.</strong> Ladies should wear casual dresses
          or shirts/blouses
          and slacks/skirts. Gentleman should wear sport shirts with slacks.
        </p>

        <h3 class="mb-[20px]">
          The Business Meeting
          <span class="whitespace-nowrap">and Drive Event</span>
        </h3>
        <p>
          <strong>Smart Casual attire.</strong>
          Shorts are acceptable. Please do not wear a tank top. Due to driving,
          shoes must be closed toe. Flip-flops are not allowed.
        </p>

        <h3 class="mb-[20px]">Finale dinner</h3>
        <p><strong>Cocktail attire.</strong> Elegant and stylish clothing.</p>
      </section>
      <section>
          <h2>EXPENSES</h2>
          <p class="mb-[36px]">
            You will be responsible for any additional charges you incur during the program.
            These include phone calls, faxes, valet, laundry services, in-room mini bar, gift shop,
            retail purchases and non-program related activities. The hotel cashier will keep a
            separate record of these expenditures for you. Please be sure to settle your personal
            account prior to your departure from the hotel.
          </p>
          <p>
            Vouchers and/or credits will be given for some meals,
            however any overages are your responsibility.
          </p>
      </section>
      <section>
          <h2>INTERNET ACCESS</h2>
          <p>There is complimentary Wi-Fi available throughout the hotel.</p>
      </section>
      <section>
          <h2>TRAVEL FUNDS</h2>
          <p>
            You should be prepared with reliable access to funds for your daily needs.
            These may include meals or snacks off property or souvenirs and other
            expenses incurred during your leisure time. It's important to have an
            alternate source of cash in case credit cards are not accepted.
          </p>
      </section>
    </div>
    <div class="flex-1 tablet:pl-[35px]">
      <section>
        <h2>MEDICAL</h2>
        <p>
          It is a good idea to consult with your physician regarding your personal
          medical needs whenever you plan to travel. If medical problems exist,
          carry a medical summary card when traveling. Essential medication,
          including allergy medicine, should be carried with you rather than packed
          in checked luggage.
        </p>
        <p>
          <strong>Note:</strong>
          <span class="italic">
            We highly recommend that you keep your necessary
            medications in their original containers and with you in your carry-on bag.
            Do not pack needed medications in your checked luggage.
          </span>
        </p>
      </section>
      <section>
        <h2>AGE POLICY</h2>
        <p>
          In accordance with Acura policy, the events and activities included in this
          program have been planned for attendance by adults only. No one under the
          age of 21 will be allowed to attend any Acura-sponsored event. This includes
          all transfers, functions, and activities.
        </p>
      </section>
      <section>
        <h2>GUEST POLICY</h2>
        <p>
          In accordance with Acura policy, this recognition trip is designed for the 2022
          COSE Platinum member and one optional adult guest only.
          No additional guests are permitted.
        </p>
      </section>
      <section class="!mb-0">
        <h2>CONTACT INFO</h2>
        <h3>Hotel</h3>

        <p class="font-AvenirBook">
          <strong>The M Resort Spa Casino</strong>
          <br />
          12300 Las Vegas Blvd. S.
          <br />
          Henderson, NV 89044
          <br />
          <a href="tel:8776737678">
            877-673-7678
          </a>
        </p>

        <h3 class="mb-[15px]">General Assistance</h3>
        <p class="font-AvenirBook !mb-0">
          If you require assistance, please contact our Acura COSE Platinum Trip Concierge:
          <br>
          9:00 a.m. - 5:00 p.m. EDT,
          <span class="whitespace-nowrap">Monday - Friday</span>
          <br>
          <a href="tel:8556538061">
            (855) 653-8061
          </a>
          <br>
          <a class="underline" href="mailto:info@AcuraCOSE.com">
            info@AcuraCOSE.com
          </a>
        </p>
      </section>
    </div>
  </div>
</template>
