<template>
    <h2 class="
        font-AvenirLtBook
        text-[2rem]
        tracking-infoSectionTitle
        text-center
        mb-3
        desktop-lg:text-[3.125rem]
        desktop-lg:mb-12"
    >
        <slot></slot>
    </h2>
</template>
