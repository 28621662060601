<template>
  <!-- <div class="h-32 bg-yellow-500"></div> -->
<div class="
  flex
  flex-col
  divide-y
  divide-gray-500
">
  <section class="
    flex
    flex-col
    tablet:flex-row
    gap-[35px]

    pb-[40px]
    tablet:pb-[70px]
  ">
    <div class="flex-1">
      <h2 class="mb-[20px] tablet:mb-[18px]">TUESDAY, JUNE 20</h2>
      <ul class="
        tablet:mb-[70px]
        mb-[40px]
      ">
        <li>Departure from home cities to Las Vegas</li>
        <li>
            Arrival at Harry Reid International airport<br />
            <span class="italic">- formerly McCarran International Airport (LAS)</span>
        </li>
        <li>
          Transportation to the M Resort Casino and Spa
          <span class="italic">(10 miles/15 - 30-minute transfer)</span>
        </li>
        <li>
          Acura Hospitality Desk in Lobby to receive a name badge and program materials
        </li>
        <li><strong>Welcome Reception and Dinner</strong></li>
      </ul>
      <h2 class="mb-[20px]">
        Welcome Reception
      </h2>
      <p>
        <strong>Business casual attire</strong>. Ladies should wear casual dresses or shirts/blouses
        and slacks/skirts. Gentleman should wear sport shirts with slacks.
      </p>
    </div>
    <div class="
      flex-1
      flex
      tablet:flex-col

      gap-[10px]
      tablet:gap-[20px]
    ">
      <div>
        <img
          class="
            w-full
            object-cover
            tablet:object-fill

            aspect-square
            tablet:aspect-auto
          "
          alt="plane"
          src="@/assets/img/itinerary/plane.webp"
        />
      </div>
      <div>
        <img
          class="
            w-full
            object-cover
            tablet:object-fill

            aspect-square
            tablet:aspect-auto
          "
          alt="poolSide" src="@/assets/img/itinerary/poolside.webp"
        />
      </div>
    </div>
  </section>
  <section class="
    flex
    flex-col
    tablet:flex-row
    gap-[35px]

    py-[40px]
    tablet:py-[70px]
  ">
    <div class="flex-1">
      <h2 class="
        mb-[16px]
        tablet:mb-[70px]
      ">WEDNESDAY, JUNE 21</h2>
      <h2 class="mb-[20px]">Free Day</h2>
      <ul class="list-disc">
        <li>Voucher for breakfast</li>
        <li>Choice of one excursion including transportation</li>
        <li>Lunch in conjunction with excursion or at leisure via&nbsp;voucher</li>
        <li>Voucher for dinner</li>
      </ul>
    </div>
    <div class="flex-1">
      <img alt="grandCanyon" src="@/assets/img/itinerary/grandCanyon.webp" />
    </div>
  </section>
  <section class="
    flex
    flex-col
    tablet:flex-row
    gap-[35px]
    py-[40px]
    tablet:py-[70px]
  ">
    <div class="
      flex-1
      tablet:[&>h3]:mb-[50px]
      [&>h3]:mb-[36px]
    ">
      <h2 class="
        mb-[16px]
        tablet:mb-[44px]
      ">THURSDAY, JUNE 22</h2>

      <h3>Business Meeting</h3>
      <h3>Transfer to Drive Event</h3>
      <h3>Drive Event with lunch</h3>
      <h3>Transfer back to hotel</h3>
      <h3>Finale Dinner</h3>

      <p class="mb-[40px] tablet:mb-[70px]">
        <strong>Please note:</strong>
        <i>
          The Business Meeting and Drive Event are reserved For
          <strong>Platinum members only and not available for Guests.</strong>
        </i>
      </p>

      <div class="mb-[40px] tablet:mb-[70px]">
        <h3 class="mb-2">
          The Business Meeting and
          <span class="whitespace-nowrap">Drive Event</span>
        </h3>
        <p>
          <strong>Smart Casual attire.</strong>
          Shorts are acceptable.
          Please do not wear a tank top. Due to driving,
          shoes must be closed toe. Flip-flops are not allowed.
        </p>
      </div>

      <div>
        <h3 class="mb-2">Finale dinner</h3>
        <p><strong>Cocktail attire.</strong> Elegant and stylish clothing.</p>
      </div>
    </div>
    <div class="flex-1">
      <img alt="fastCars" src="@/assets/img/itinerary/fastCars.webp" />
      <h2 class="mt-[40px] mb-[20px]">Driving Experience</h2>
      <ul>
        <li>Please bring a valid driver's license</li>
        <li>Members/drivers must be 21 to drive in the event</li>
        <li>Members/drivers should be experienced in operating a manual transmission vehicle</li>
        <li>No drugs, alcohol or weapons are permitted</li>
        <li>
          Members/drivers may be required to take a breathalyzer
          test before driving, if deemed necessary
        </li>
        <li><strong>Acura reserves the right to deny any members a drive experience</strong></li>
        <li
          class="mb-0"
        >Guests will <strong>not</strong> be able to drive</li>
      </ul>
    </div>
  </section>
  <section class="
    flex
    flex-col
    tablet:flex-row
    gap-[35px]

    pt-[40px]
    tablet:pt-[70px]
  ">
    <div class="flex-1">
      <h2 class="
        mb-[20px]
        tablet:mb-[70px]
      ">FRIDAY, JUNE 23</h2>
      <h3 class="mb-[20px]">Hotel Checkout</h3>
      <ul>
        <li>Transportation to the Harry Reid International Airport <i>
          (10 miles/15 - 30-minute transfer)
        </i></li>
        <li>Depart to home cities</li>
      </ul>
    </div>
    <div class="flex-1">
      <img alt="fastCars" src="@/assets/img/itinerary/biggerPlane.webp" />
    </div>
  </section>
</div>

</template>

<style>
.clip-square {
  /* clip-path: inset(0 0 0 0);
  width: 200px;
  height: 200px; */
}
</style>
