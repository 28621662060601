<template>
    <div>
        <!-- Tab Navigation -->
        <div class="
          mb-8
          tablet:mb-12
          desktop-lg:mb-16
          border-b
          border-[#707070]"
        >
          <div class="
            flex
            gap-8
            desktop-lg:gap-14
            overflow-x-auto
            mx-auto
            px-4
            desktop-lg:px-16"
            ref="container"
          >
            <button v-for="(tab, index) in tabs" :key="index"
              @click="selectAndCenter($event, index)"
              :class="{
                'after:bg-red-600': activeTab === index,
                'after:bg-transparent': activeTab !== index
              }"
              class="
                font-AcuraBespokeReg
                text-lg text
                desktop-lg:text-[1.75rem]
                pt-2 pb-4
                relative
                min-w-max
                cursor-pointer
                after:absolute
                after:h-1
                after:w-full
                after:bottom-1
                after:left-0
                after:rounded-sm
              "
            >
              {{ tab.title }}
            </button>
          </div>
        </div>
        <!-- Active Tab Content Section -->
        <div class="
          px-4
          tablet:px-6
          desktop-lg:px-16
        ">
            <TransitionFadeInBlurFadeOutBlur>
                <slot :name="tabs[activeTab].slotName" :title="tabs[activeTab].title">
                </slot>
            </TransitionFadeInBlurFadeOutBlur>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import TransitionFadeInBlurFadeOutBlur from './TransitionFadeInBlurFadeOutBlur.vue';

const tabs = ref([
  { title: 'WHAT’S INCLUDED', slotName: 'whatsIncluded' },
  { title: 'DESTINATION', slotName: 'destination' },
  { title: 'HOTEL', slotName: 'hotel' },
  { title: 'ITINERARY', slotName: 'itinerary' },
  { title: 'TRAVEL', slotName: 'travel' },
  { title: 'GENERAL INFO', slotName: 'generalInfo' },
  { title: 'FAQS', slotName: 'faqs' },
]);

const activeTab = ref(0);

const container = ref(null);

const selectAndCenter = (e, index) => {
  const el = e.srcElement;

  if (el) {
    const viewportWidth = container.value.clientWidth;
    const buttonWidth = el.getBoundingClientRect().width;

    const xcoord = el.offsetLeft - (viewportWidth / 2) + (buttonWidth / 2 - 16);
    container.value.scrollTo(xcoord, 0);
  }

  activeTab.value = index;
};
</script>
