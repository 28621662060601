<template>
  <footer class="
    acura-footer
    text-[18px]
    tablet:text-[14px]

    flex flex-col
    items-center
    justify-between

    w-full
    tablet-sm:w-[95%]
    desktop-xxl:w-[90%]

    px-[20px]
    pre-mobile:px-[36px]

    mx-auto
    font-AvenirRoman
  ">
    <div class="
      flex
      flex-col
      tablet-sm:flex-row
      tablet:justify-between

      items-center

      w-full
      tablet:py-14
      py-10
      pre-mobile:gap-4
    ">
      <div class="
        flex-col
        gap-4
        text-[16px]
        tablet:text-[18px]
        flex-1
        leading-[38px]
      ">
        <span class="
          block
          mb-2
          font-AvenirHeavy
          leading-normal
        ">
          For help, please contact the Acura COSE Platinum
          <span class="whitespace-nowrap">Trip Concierge</span>:
        </span>

        <span class="
          block
          leading-[38px]
        ">
          9:00 a.m. - 5:00 p.m. EDT,
          <span class="whitespace-nowrap">Monday - Friday</span>
        </span>
        <div>
          <a href="tel:8556538061">(855) 653-8061</a>
        </div>
        <div>
          <a href="mailto:info@AcuraCOSE.com">
            info@AcuraCOSE.com
          </a>
        </div>
      </div>
      <div class="
        flex
        justify-end
        gap-4
        tablet:gap-12
        tablet:flex-1
        flex-0
      ">
        <img class="
          acura-footer__logo
          desktop-xl:w-auto
          tablet:w-[160px]
          w-[120px]
        " src="@/assets/img/svg/brand--acura.svg" alt="Logo Acura">
        <img
          class="
            w-[100px]
            desktop-xl:w-[160px]
          "
          src="@/assets/img/svg/trophy.svg"
          alt="Trohpy, Counsil Of Sales Excellence"
        >
      </div>
    </div>
    <p class="
      text-[12px]
      tablet:text-[16px]
      desktop-xxl:text-[18px]
      border-t
      border-t-gray-500
      m-0
      py-10
      leading-normal
    ">
        © 2023 Acura. Acura reserves the right to modify,
        amend, cancel, or revoke, in whole or in part, this offer at any time
        and without prior notice. In all matters pertaining to interpretation
        and application of any rule or phase of the program, the decision of
        Acura will be final. Trip, awards, and activities cannot be transferred
        or exchanged.
    </p>
  </footer>
</template>
