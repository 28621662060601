<template>
  <button
    class="
      acura-button
    "
    @click="toLink"
  >
    <slot></slot>
  </button>
</template>

<script setup>
const props = defineProps({
  for: String, // anchor id, inspired by <label for="">
  href: String,
});

const toLink = () => {
  if (props.href) {
    window.open(props.href, '_blank');
  } else if (props.for) {
    document.getElementById(props.for).scrollIntoView({ behavior: 'smooth' });
  }
};
</script>

<style lang="scss" scoped>
.acura-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-transform: uppercase;
  border: 2px solid #e82c2a;
  outline: 0;
  border-radius: 35px;
  color: #fff;
  box-shadow:
    0 0 8px 0 rgb(232 44 42),
    inset 0 0 6px 0 rgb(232 44 42);
  cursor: pointer;
  transition: all .3s ease-in;
  font-weight: 500;
  &:hover {
    box-shadow:
      0 0 20px 0 rgb(232 44 42),
      inset 0 0 6px 0 rgb(232 44 42);
  }

  &.white {
    box-shadow:
      0 0 8px 0 white,
      inset 0 0 6px 0 white;

    border-color: white;
  }
}
</style>
