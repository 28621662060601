<template>
<div class="
  flex
  flex-col
  divide-y
  divide-gray-500
">
  <section class="
    flex
    flex-col
    tablet:flex-row
    gap-[40px]
    tablet:gap-[70px]

    pb-[40px]
    tablet:pb-[70px]
  ">
    <div class="flex-1">
      <ul>
        <li>As a Platinum member, your airline ticket is complimentary</li>
        <li>If you choose to bring a guest, booking their flight will be your
            and/or your guest's responsibility
        </li>
        <li>
          Airport transfers are included for both
          members and guests from the member's location
        </li>
        <li>
          You will receive a confirmation email after you register
          along with a Trip Profile form to be filled out and returned
        </li>
      </ul>
    </div>
    <div class="flex-1">
      <img alt="airport luggage" src="@/assets/img/travel/airportLuggage.webp" />
    </div>
  </section>
  <div
    class="
      flex
      flex-col

      tablet:flex-row
      tablet:divide-x
      tablet:divide-gray-500

      pt-[40px]
      tablet:pt-[70px]

      [&_section]:mb-[54px]
      tablet:[&_section]:mb-[70px]

      [&_h2]:mb-[12px]
      tablet:[&_h2]:mb-[20px]
  ">
    <div class="flex-1 tablet:pr-[35px]">
      <section>
        <h2>AIRPORT</h2>
        <p class="mb-[30px]">
          Harry Reid <span class="whitespace-nowrap">International Airport</span> (LAS)
          <br />
          5757 <span class="whitespace-nowrap">Wayne Newton Blvd</span>
          <br />
          Las Vegas
          <br />
          NV 89119
          <br />
          <a href="tel:7022615211">702-261-5211</a>
        </p>
        <p>
          Las Vegas Airport, officially known as Harry Reid
          International Airport, is the main commercial airport serving Las Vegas,
          Nevada. LAS Airport is located in Paradise, 5 miles south of downtown
          Las Vegas, in Clark County.
        </p>
      </section>
      <section>
        <h2>AIR ARRANGEMENTS</h2>
        <p class="mb-[30px]">
          Acura will begin making the airline arrangements for your trip upon completion
          of your registration. Flights will be selected for you based on Acura guidelines
          and in accordance with the program's designated airfares. Please be aware that
          the rules associated with these designated airfares may require traveling on a
          preselected airline carrier and the use of a non-refundable, non-transferable
          ticket.
        </p>
        <p>
          <strong>Note:</strong> <i>If you require special travel arrangements, please
          indicate your plans during registration. This will enable us to handle your
          request prior to ticketing. If you make changes after tickets are issued,
          you will be responsible for any additional costs charged by the airlines.</i>
        </p>
      </section>
      <section>
        <h2>TRAVEL INSURANCE</h2>
        <p>
          You may wish to consider purchasing your own travel insurance to supplement
          your regular life, accident, medical and personal property insurance.
        </p>
      </section>
      <section>
        <h2>AIRLINE CHECK-IN</h2>
        <p class="mb-[30px]">
          Upon airline check-in, you must present a government issued ID,
          such as a passport or driver's license. If you do not have
          either of these documents, you must show two other forms of identification,
          one of which must be issued by a government agency. It is important to have
          the proper identification to avoid possible delays.
        </p>
        <p>
          <strong>Note:</strong>
          <span class="italic">
            We suggest you make
            photocopies of your identification materials. Leave a copy at home with your
            emergency contact and also bring a copy with you. Remember to keep this copy
            separate from your other identification. If your normal identification is lost
            or stolen, the photocopy will help facilitate obtaining replacements.
          </span>
        </p>
      </section>
    </div>
    <div class="flex-1 tablet:pl-[35px]">
      <section>
        <h2>SECURITY</h2>
        <p>
          Allow extra time as heightened security measures require more time to
          properly screen travelers. It is recommended that travelers check in at
          least 90 minutes prior to domestic flights. You should be prepared to
          show proper identification at the ticket counter, security checkpoint
          and the boarding gate, along with your airline issued boarding pass.
          Only ticketed passengers are allowed beyond the security checkpoints.
          Arrangements can be made with the airlines for travelers needing special
          assistance to get to the gate. Limit metal objects worn on your person or clothing.
          Remove metal objects such as keys, cell phones, coins etc., prior to passing
          through the metal detectors to facilitate the screening process. Putting metal
          objects in your carry-on bag will expedite the process of going through the
          metal detector.
        </p>
      </section>
      <section>
        <h2>ARRIVAL AND TRANSPORTATION</h2>
        <p class="mb-[30px]">
          Upon arrival at Harry Reid International Airport, look for the 2023 COSE
          Platinum Trip sign in the baggage claim area. Travel staff will be on hand
          to direct you to transportation for the M Resort.
        </p>
        <p>
          Note: Transportation will be available from
          the airport based on your scheduled flight
        </p>
      </section>
      <section>
        <h2>VALUABLES</h2>
        <p>While in route, carry your travel documents, valuables and vital medicines in
          your purse or carry-on bag. Do not pack these items in your luggage. It's best to
          leave valuable jewelry at home and use the hotel safe deposit box for your excess cash.
        </p>
      </section>
      <section class="
        rounded-[10px]
        border-acura-ring-red
        border-2
        p-[35px]
      ">
        <h2 class="mb-4">ON-SITE ASSISTANCE</h2>
        <p class="font-AvenirBlack">
          <strong>Throughout your stay, our Acura hospitality team will be available.</strong>
        </p>
      </section>
      <section class="
        rounded-[10px]
        border-acura-ring-red
        border-2
        p-[35px]
        !mb-0
      ">
        <h2 class="mb-4">NAME BADGE</h2>
        <p class="font-AvenirBlack">
          <strong>
            Please wear your name badge to all group events as a courtesy
            to your fellow attendees and to identify yourself as a guest of Acura.
          </strong>
        </p>
      </section>
    </div>
  </div>
</div>
</template>
