<template>
  <transition
    mode="out-in"
    enter-active-class="fade-in-blur"
    leave-active-class="fade-out-blur"
  >
    <slot />
  </transition>
</template>

<style lang="scss" scoped>
.fade-in-blur {
  animation: fade-in-blur linear 0.2s;
}

.fade-out-blur {
  animation: fade-out-blur linear 0.2s;
}

@keyframes fade-in-blur {
  0% {
    filter: blur(10px);
    opacity: 0.2;
  }

  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes fade-out-blur {
  0% {
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    filter: blur(10px);
    opacity: 0.2;
  }
}
</style>
