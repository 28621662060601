const faqsData = [
  {
    question: 'WHAT IS INCLUDED?',
    answer: '<p>Your 2023 COSE Platinum Trip includes:</p>',
    details: [
      'Air Travel for Platinum member only',
      'Roundtrip Airport Transfers for both member and a guest (from member\'s location)',
      'A beautifully appointed room',
      'A choice of one Excursion for both member and guest',
      'Drive Event for member only',
      'Meals for both member and guest, via voucher or group functions',
      'Welcome Gift for member',
    ],
  },
  {
    question: 'MAY I BRING A GUEST?',
    answer: '<p>Platinum members may invite an adult guest to attend. If you choose to bring a guest, booking their flight will be your and/or guest\'s responsibility. A $400 flight credit is included (upon arrival) for members who bring a guest (and cannot be redeemed for cash).</p><p>Included for guests are:</p>',
    details: [
      'Airport Transfers (from member\'s location)',
      'Room (shared with member)',
      'Excursion Choice',
      'Meals',
    ],
  },
  {
    question: 'WILL WE BE DRIVING THE NEW INTEGRA TYPE S?',
    answer: '<p>As part of this recognition trip, you will have the opportunity to drive the new 2024 Acura Integra Type S Please bring a valid driver\'s license.</p>',
  },
  {
    question: 'CAN YOU TELL ME MORE ABOUT THE DRIVING EXPERIENCE?',
    details: [
      'Please bring a valid driver\'s license',
      'Members/drivers must be 21 to drive in the event',
      'Members/drivers should be experienced in operating a manual transmission vehicle',
      'Members/drivers may be required to take a breathalyzer test before driving, if deemed necessary',
      'Acura reserves the right to deny any members a drive experience',
      'Guests will not be able to drive',
    ],
  },
  {
    question: 'WHAT HAPPENS AFTER I REGISTER?',
    details: [
      'You will receive a confirmation email containing a Trip Profile',
      'Please complete the Trip Profile and return to our COSE Platinum Trip Concierge at <a href="mailto:info@AcuraCOSE.com" class="underline">info@AcuraCOSE.com</a>',
      'Your flight information and other trip details will be conveyed following the submission of your <span style="white-space: nowrap;">Trip Profile</span>',
    ],
  },
];

export default faqsData;
