<template>
  <header class="
    border-b
    border-[#7e7e7e]
    desktop-xxl:pt-4
    desktop-xxl:pb-6
    tablet:pt-3
    tablet:pb-3
    py-2
    bg-black
  ">
    <div class="
      flex
      items-center
      justify-between
      pre-mobile:w-[95%]
      w-[90%]
      mx-auto
    ">
      <a href="#" title="Acura COSE Platinum Recognition Trip">
        <img
          class="
            desktop-xxl:w-auto
            pre-mobile:w-[180px]
            w-[120px]
          "
          src="@/assets/img/svg/brand--acura.svg"
          alt="Logo Acura"
        >
      </a>
      <div>
        <AcuraButton
          :href="registrationHref"
          class="
            desktop-xxl:text-[24px]
            desktop-xxl:w-[280px]
            desktop-xxl:h-[70px]
            pre-mobile:text-[18px]
            pre-mobile:w-[200px]
            pre-mobile:h-[50px]
            text-[12px]
            w-[130px]
            h-[40px]
          "
        >Registration</AcuraButton>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue';
import AcuraButton from './AcuraButton.vue';

const registrationHref = ref(process.env.VUE_APP_REGISTRATION);
</script>
