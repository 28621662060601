<template>
<div class="
  video-hero
  relative
  desktop-xxl:pb-[150px]
  desktop-lg:pb-[125px]
  tablet-sm:pb-[100px]
  overflow-hidden
">
    <div class="
      overlay
      absolute
      z-10
      h-full
      w-full
      bg-gradient-to-t

      from-black
        pre-mobile:from-0%
        from-50%

      to-transparent
        pre-mobile:to-30%
        to-65%
    "></div>
    <video autoplay loop muted playsinline
      class="
        absolute
        w-full
        z-0
        contrast-[1.1]
      "
    >
      <source src="@/assets/vids/city_1280_720_crop.mp4" type="video/mp4">
    </video>
    <div class="
        desktop:max-w-[1200px]
        desktop-xxl:max-w-[1553px]

        mt-[100px]
        pre-mobile:mt-[30px]
        desktop-lg:mt-[70px]
        desktop-xxl:mt-[100px]

        px-4
        pb-4
        mx-auto
        relative
        z-20
      "
    >
      <img
        class="
          pre-mobile:w-2/3
          relative
          left-[-2px]
        "
        src="@/assets/img/Headline.webp"
        alt="Cose Platinum Recognition Trip"
      />
      <div class="
        flex
        gap-[12px]
        mt-4"
      >
        <AcuraButton
          for="information"
          class="
            white
            bg-[#00000075]
            desktop-xxl:text-[24px]
            desktop-xxl:w-[280px]
            desktop-xxl:h-[70px]
            pre-mobile:text-[18px]
            pre-mobile:w-[200px]
            pre-mobile:h-[50px]
            text-[12px]
            w-1/2
            h-[40px]
        ">INFORMATION</AcuraButton>
        <AcuraButton
          :href="registrationHref"
          class="
            bg-[#00000075]
            desktop-xxl:text-[24px]
            desktop-xxl:w-[280px]
            desktop-xxl:h-[70px]
            pre-mobile:text-[18px]
            pre-mobile:w-[200px]
            pre-mobile:h-[50px]
            text-[12px]
            w-1/2
            h-[40px]
        ">REGISTRATION</AcuraButton>
      </div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue';
import AcuraButton from './AcuraButton.vue';

const registrationHref = ref(process.env.VUE_APP_REGISTRATION);
</script>
