<template>
    <!-- <div class="h-32 bg-red-500"></div> -->
    <div class="hotel">
      <section class="
        tablet:mb-[70px]
        mb-[60px]
      ">
        <div class="
          flex
          flex-col
          items-center

          mb-[24px]
          tablet:mb-[70px]

          [&>*]:mb-[20px]
        ">
          <h3 class="text-center">
            THE M RESORT SPA&nbsp;CASINO
          </h3>
          <p class="text-center">
            12300 Las Vegas Blvd. S.
            <span class="
              text-acura-red
              relative
              top-[-5px]
              px-2
              text-[21px]

              hidden
              tablet:inline-block
            ">|</span>
            Henderson, NV 89044
          </p>
          <p class="tablet:!mb-[20px] !mb-0">
            <a href="tel:8776737678">877-673-7678</a></p>
          <p><a target="_blank" href="http://www.themresort.com">www.themresort.com</a></p>
        </div>
        <img
          src="@/assets/img/hotel/bed.webp"
          alt=""
          class="mb-4 mx-auto w-full"
        />
        <p class="
          text-center
          tablet:mt-[70px]
          mt-[20px]
          tablet:w-[80%]
          mx-auto
        ">
          This resort spa and casino offers upscale rooms with comfortable décor.
          Guest rooms include a 42-inch flat-screen HDTV, an in-room refreshment center,
          a built-in desk with an executive chair and free local calls. The private bathrooms
          boast marble floors and a separate bathtub and shower. TVs are also provided in
          each bathroom. The property includes a 92,000 sq ft casino, a 16-story tower
          with 390 rooms and a poolside bar.
        </p>
      </section>
      <section class="
        flex
        flex-col
        tablet:flex-row
        tablet:gap-[70px]
        gap-[20px]
      ">
        <div class="flex-1">
          <h2 class="mb-2">ROOM INFORMATION</h2>
          <h3 class="mb-8">
            As recognition of your Platinum Status, your room is complimentary.
          </h3>
          <p>
            Enter the M Resort hotel room and you're immediately greeted by rich woods,
            recessed lighting and clean lines. Stretch out on the inviting pillow-top mattress
            or relax in the deep soaking tub. Floor-to-ceiling windows offer copious sunlight
            during the day and amazing views of the Las Vegas Strip or mountains in the evening.
            Enjoy tech features like high-speed internet, convenient USB outlets, and a 42-inch
            HD LED flat-screen TV.
          </p>
        </div>
        <div class="flex-1">
          <img
            src="@/assets/img/hotel/poolView.webp"
            alt=""
            class="
              tablet:mb-4 mx-auto w-full
            "
          />
        </div>
      </section>
    </div>
</template>
