<template>
    <div class="
      max-w-[1180px]
      mx-auto
      text-center
      relative
    ">
      <h1 class="
        z-10
        relative
        font-AvenirLight
        text-[28px]
        desktop-xl:text-[50px]

        tracking-[1.01px]
        desktop-xl:tracking-[1.8px]

        leading-tight
        desktop-xl:leading-[1.1]
        desktop-xl:mb-10
        mb-8
      ">
        Congratulations on achieving Platinum status in the 2022 Council of
        Sales Excellence (COSE)!
      </h1>
      <p class="
        font-AvenirLight
        text-[24px]
        mb-8
        desktop-xl:text-[38px]
        desktop-xl:leading-[56px]
        desktop-xl:tracking-[1.37px]
        desktop-xl:mb-10
      ">
        You have earned an Acura COSE Platinum Recognition Trip to Las
        Vegas, June 20 - 23rd at the M Resort Casino&nbsp;and&nbsp;Spa.
      </p>
      <p class="
        text-[18px]
        tracking-[0.65px]
        px-4
        mb-8
        desktop-xl:text-[24px]
        desktop-xl:leading-relaxed
        desktop-xl:tracking-[0.86px]
        desktop-xl:px-1
        desktop-xl:mb-10
      ">
        Thank you for your ongoing commitment to our clients, our brand, and the
        COSE program. We look forward to celebrating your achievement with you
        in Las Vegas!
      </p>
      <p class="
        text-[18px]
        tracking-[0.65px]
        px-4
        mb-8
        desktop-xl:text-[24px]
        desktop-xl:leading-relaxed
        desktop-xl:tracking-[0.86px]
        desktop-xl:px-1
        desktop-xl:mb-10
      ">
          This website provides details about your trip including
          destination, hotel information, and a preliminary itinerary. After
          reviewing, please click on “Registration” to complete your travel
          registration no later than <strong>May 19th</strong>.
      </p>
      <AcuraButton
        :href="registrationHref"
        class="
          w-[80%]
          pre-mobile:w-[200px]
          desktop-xl:w-[280px]

          h-[50px]
          pre-mobile:h-[50px]
          desktop-xl:h-[70px]

          text-[18px]
          pre-mobile:text-[18px]
          desktop-xl:text-[24px]

          tracking-[1.01px]
          mx-auto
      ">
          Registration
      </AcuraButton>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import AcuraButton from './AcuraButton.vue';

const registrationHref = ref(process.env.VUE_APP_REGISTRATION);
</script>
